<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

import {
  authMethods,
  notificationMethods,
} from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    tryToLogIn() {
      this.submitted = true;
      this.authError = null;
      this.$v.$touch();
    
      if (!this.$v.$invalid) {
        const { email, password } = this;
        this.tryingToLogIn = true;

        this.login({
            email: email,
            password: password
        }).then(() => {
          this.tryingToLogIn = false;
        });
      }
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20">Seja Bem Vindo(a) !</h5>
                <p class="text-white-50">Logue-se com sua conta.</p>
                <a href="/" class="logo logo-admin">
                  <img
                    src="@/assets/images/logo-sm.png"
                    height="24"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >
                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <b-form
                  @submit.prevent="tryToLogIn"
                  class="form-horizontal mt-4"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Email"
                    label-for="input-1"
                    class="mb-3"
                    label-class="form-label"
                  >
                    <b-form-input
                      id="input-1"
                      :class="{ 'is-invalid': submitted && $v.email.$error }"
                      v-model="email"
                      type="email"
                      placeholder="Enter email"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">Email é necessário.</span>
                      <span v-if="!$v.email.email"
                        >Por favor, insira um email válido.</span
                      >
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Password"
                    label-for="input-2"
                    class="mb-3"
                    label-class="form-label"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="password"
                      type="password"
                      placeholder="Enter password"
                      :class="{ 'is-invalid': submitted && $v.password.$error }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.password.required"
                      class="invalid-feedback"
                    >
                      É necessário inserir a Senha.
                    </div>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          class="form-check-label"
                          for="customControlInline"
                          >Lembrar-me</label
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 text-end">
                      <b-button type="submit" variant="primary" class="w-md"                      
                        v-if="!tryingToLogIn"
                        >Log In</b-button
                      >
                      <b-spinner
                        v-if="tryingToLogIn"
                        label="Spinning"
                        variant="info"
                        class="m-1"
                      ></b-spinner>
                    </div>
                  </div>

                  <div class="mt-2 mb-0 row">
                    <div class="col-12 mt-4">
                      <router-link to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Esqueceu a senha?
                      </router-link>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
